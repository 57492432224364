// eslint-disable-next-line import/order
import { i18n } from './plugins/i18n'
import './plugins/vue-composition-api'
import './styles/styles.scss'
// eslint-disable-next-line import/order
import Vue from 'vue'
import './plugins/acl'
import './plugins/vue-toastification'
import './plugins/axios'
import './plugins/json-viewer'
import './plugins/leaflet'
import './plugins/vcalendar'
import './plugins/vue-perfect-scrollbar'
import './plugins/v-perfect-scrollbar'
import './plugins/vue-papa-parse'
import './plugins/vue-html-to-paper'
import './plugins/vue-json-csv'
import './plugins/moment'
import './plugins/vue-country-flag'
import './plugins/fragment'
import './plugins/currency'
import './plugins/vue-echo-laravel'
import './plugins/tiptap-vuetify'
import './plugins/video-embed'
// eslint-disable-next-line import/order
import router from './router'
// eslint-disable-next-line import/order
import store from './store'

// eslint-disable-next-line import/order
import VueRx from 'vue-rx'
// eslint-disable-next-line import/order
import VuejsClipper from 'vuejs-clipper/dist/vuejs-clipper.umd.min'
import 'vuejs-clipper/dist/vuejs-clipper.css'
// eslint-disable-next-line import/order
import SlimCropper from 'vue-slim-cropper'

// VIDEOS
// eslint-disable-next-line import/order
import Embed from 'v-video-embed'
// eslint-disable-next-line import/no-unresolved
import vPlayBack from 'v-playback'
import vuetify from './plugins/vuetify'
import App from './App.vue'

Vue.use(VueRx)
Vue.use(VuejsClipper, {
  components: {
    clipperBasic: 'clipper-basic',
    clipperPreview: 'clipper-preview',
  },
})
Vue.use(SlimCropper)
// global register
Vue.use(Embed)
Vue.use(vPlayBack)

Vue.config.productionTip = false

export default new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
