import Vue from 'vue'
import VueRouter from 'vue-router'
import apps from './apps'
import dashboard from './dashboard'
import pages from './pages'
import userInterface from './user-interface'
import nomencladores from './nomencladores'
import userRegister from './user-register'
import markups from './administration/markups'
import configuration from './configuration'
import booking from './booking'
import json from './json'
import products from './products'
import contratos from './contratos'
import logs from './logs'
import moderations from './moderations'
import tutorials from './tutorials'

// import auth from '../middleware/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // eslint-disable-next-line no-unused-vars
    redirect: to => ({ name: 'auth-login' }),
  },
  {
    path: '/login',
    name: 'auth-login',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  ...dashboard,
  ...booking,
  ...userInterface,
  ...nomencladores,
  ...userRegister,
  ...markups,
  ...configuration,
  ...json,
  ...products,
  ...contratos,
  ...logs,
  ...moderations,
  ...tutorials,
  ...apps,
  ...pages,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  if (!subsequentMiddleware) return context.next

  return (...parameters) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

router.beforeEach((to, from, next) => {
  // Redirect if logged in
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      to,
    }
    const nextMiddleware = nextFactory(context, middleware, 1)

    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()
})

export default router
